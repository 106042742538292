export function isNotNil<T>(item: T | null | undefined | 0): item is T {
  return !!item;
}
export function isNotUndefined<T>(item: T | null | undefined | 0): item is T {
  return typeof item !== 'undefined';
}

export const mustBe = <T>(value: T | undefined): T => {
  if (typeof value !== 'undefined') {
    return value;
  }
  throw new Error('Expected value to be defined');
};

export function isPromise<T>(val: T | Promise<T>): val is Promise<T> {
  return val && (val as Promise<T>).then !== undefined;
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>; // make some properties of a type optional

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const transformLink = (link: string) => {
  if (link.indexOf('http://') !== 0 && link.indexOf('https://') !== 0) {
    return `https://${link}`;
  }
  return link;
};
