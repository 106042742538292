import { MessageData, ZamokMessage } from '@advancedkiosks/mc-webview/lib/types';
import { CommonAction, CommonState } from '@advancedkiosks/types-common/lib';
import { isNotNil } from '@advancedkiosks/lib-utils/lib/ts-helpers';
import { BehaviorSubject } from 'rxjs';
import { Observable, fromEvent } from 'rxjs';
import { shareReplay, timeout } from 'rxjs/operators';
import { filter, first, map, share } from 'rxjs/operators';

function toZamokMessage({ data, origin, source }: MessageEvent) {
  return { message: data as ZamokMessage, origin, source };
}

interface ApiParams {
  topWindowOrigin?: string;
  topWindowRef?: Window;
}

export class Zamok {
  public readonly messages$: Observable<ReturnType<typeof toZamokMessage>>;
  public readonly state$ = new BehaviorSubject<CommonState | null>(null);
  public readonly data$: Observable<Omit<MessageData, 'type'>>;
  public readonly ready: Promise<{ window: Window; origin: string }>;
  static api(params?: ApiParams) {
    return new Zamok(params);
  }
  private constructor(params?: ApiParams) {
    this.messages$ = fromEvent<MessageEvent>(window, 'message').pipe(
      filter(({ data }) => ['zamok-api-ready', 'zamok-data'].includes(data?.type)),
      timeout(15000),
      map(toZamokMessage),
      share(),
    );
    const firstMessage$ = this.messages$.pipe(first(), shareReplay());
    this.ready = firstMessage$
      .pipe(
        filter(({ source }) => !!source),
        map(({ source, origin }) => ({ window: source as Window, origin })),
      )
      .toPromise();
    this.messages$.pipe(map(({ message }) => message.state)).subscribe(this.state$);
    this.data$ = this.messages$.pipe(
      map(({ message }) => (message.type === 'zamok-data' ? { state: message.state, action: message.action } : null)),
      filter(isNotNil),
    );
    if (params) {
      const { topWindowOrigin, topWindowRef } = params;
      if (topWindowOrigin) {
        const top =
          topWindowRef ||
          (() => {
            let result = window.parent;
            while (result.parent !== result) {
              result = result.parent;
            }
            return result;
          })();
        top.postMessage({ type: 'zamok-api-please' }, topWindowOrigin);
      }
    }
  }
  public getState(props?: { needProps?: string[] }) {
    const result$ = this.state$.pipe(filter(isNotNil), first());
    if (props?.needProps) {
      return result$.pipe(filter((state) => props.needProps!.every((prop) => prop in state))).toPromise();
    }
    return result$.toPromise();
  }
  public dispatch(action: CommonAction): Promise<CommonState> {
    return this.ready.then(({ origin, window }) => {
      const result = this.data$
        .pipe(
          filter(({ action: { type } }) => type === action.type),
          map(({ state }) => state),
          first(),
        )
        .toPromise();
      window.postMessage({ type: 'zamok-action', action }, origin);
      return result;
    });
  }
  public waitForAction(type: string, timeoutMs?: number | Date) {
    const result$ = this.data$.pipe(
      filter(({ action }) => action.type === type),
      first(),
    );
    if (timeoutMs) {
      return result$.pipe(timeout(timeoutMs)).toPromise();
    }
    return result$.toPromise();
  }
}
